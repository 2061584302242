.custom-speed-dial .MuiSpeedDial-fab {
    background-color: #2a83eb;
    width: 35px !important;
    height: auto !important;
    padding: 0;
}
.swal2-container {
    overflow-y: auto;
    /* position: absolute; */
    z-index: 10000;
}
.event-selected-images {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.event-selected-images .imgs {
    width: 45%;
}
.event-selected-images .imgs img {
    object-fit: cover;
    width: 100%;
    height: 200px;
}
